<script lang="ts" setup>
import {
	CONFIRM_CLAIM_MODAL_TITLE,
	CONFIRM_CLAIM_MODAL_MESSAGE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import new_email from '@/assets/icons/new_email.svg';

const { email } = storeToRefs(useUserStore());
</script>

<template>
	<div class="confirm-claim-modal">
		<ModalCommonContent>
			<template #header>{{ $t(CONFIRM_CLAIM_MODAL_TITLE) }}</template>
			<template #message>
				<img :src="new_email" alt="congratulations" class="scale-in" />
				<PrimeText tag="div" size="sm">
					<span v-html="$t(CONFIRM_CLAIM_MODAL_MESSAGE, { email })" />
				</PrimeText>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style scoped lang="scss">
:deep(.p-modal-content) {
	.confirm-claim-modal .p-without-border-bottom & {
		padding-bottom: 1.5rem !important;
	}
}
</style>
