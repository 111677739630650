<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { transformCurrency } from '@/utils/helpers';
import { PiInfo } from '@primeinsightsgroupllc/prime-icons';
import { computed } from 'vue';
import { BALANCE } from '@/locales/constants';

const { collectedCoins, currency } = storeToRefs(useUserStore());
const balanceText = computed(() => {
	return `${transformCurrency(+collectedCoins.value!, currency.value, 2)}`;
});
</script>

<template>
	<div class="reward-info-wrapper">
		<PiInfo color="base-primary" />
		<PrimeText color="primary" size="sm">
			{{ $t(BALANCE) }}: {{ balanceText }}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.reward-info-wrapper {
	display: flex;
	flex-wrap: wrap;
	border-radius: 1.125rem;
	background: #e6f1ee;
	padding: 0.75rem 1.125rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
}
</style>
