// App Login View
export const AUTH_LOGIN_PAGE_WRAPPER = 'auth-login-page-wrapper';
export const AUTH_LOGIN_MAIN_CONTENT = 'auth-login-main-content';
export const AUTH_LOGIN_HEADER = 'auth-login-header';
export const AUTH_LOGIN_LOGO = 'auth-login-logo';
export const AUTH_LOGIN_TITLE = 'auth-login-title';
export const AUTH_LOGIN_LEFT_SECTION = 'auth-login-left-section';
export const AUTH_LOGIN_MAIN_HEADING = 'auth-login-main-heading';
export const AUTH_LOGIN_SUB_HEADING = 'auth-login-sub-heading';
export const AUTH_LOGIN_FEATURE_TAGS = 'auth-login-feature-tags';
export const AUTH_LOGIN_CARD = 'auth-login-card';
export const AUTH_LOGIN_CARD_HEADER = 'auth-login-card-header';
export const AUTH_LOGIN_CARD_LOGO = 'auth-login-card-logo';
export const AUTH_LOGIN_CARD_TITLE = 'auth-login-card-title';
export const AUTH_LOGIN_CARD_SUBTITLE = 'auth-login-card-subtitle';
export const AUTH_LOGIN_FORM = 'auth-login-form';
export const AUTH_LOGIN_EMAIL_INPUT = 'auth-login-email-input';
export const AUTH_LOGIN_SUBMIT_BUTTON = 'auth-login-submit-button';
export const AUTH_LOGIN_SOCIAL_PROVIDERS = 'auth-login-social-providers';

// Verify Email View
export const AUTH_VERIFY_EMAIL_WRAPPER = 'auth-verify-email-wrapper';
export const AUTH_VERIFY_EMAIL_CONTENT = 'auth-verify-email-content';
export const AUTH_VERIFY_EMAIL_SPINNER = 'auth-verify-email-spinner';

// Socials View
export const AUTH_SOCIAL_WRAPPER = 'auth-social-wrapper';
export const AUTH_SOCIAL_MESSAGE = 'auth-social-message';
export const AUTH_SOCIAL_EMAIL = 'auth-social-email';
export const AUTH_SOCIAL_BUTTON = 'auth-social-button';
export const AUTH_SOCIAL_CHANGE_ACCOUNT = 'auth-social-change-account';

// Signup Social View
export const AUTH_SIGNUP_SOCIAL_FORM = 'auth-signup-social-form';
export const AUTH_SIGNUP_SOCIAL_PROVIDER = 'auth-signup-social-provider';
export const AUTH_SIGNUP_SOCIAL_EMAIL = 'auth-signup-social-email';
export const AUTH_SIGNUP_SOCIAL_SUBMIT = 'auth-signup-social-submit';
export const AUTH_SIGNUP_SOCIAL_POLICY = 'auth-signup-social-policy';

// Signup Complete View
export const AUTH_SIGNUP_COMPLETE_WRAPPER = 'auth-signup-complete-wrapper';
export const AUTH_SIGNUP_COMPLETE_ICON = 'auth-signup-complete-icon';
export const AUTH_SIGNUP_COMPLETE_MESSAGE = 'auth-signup-complete-message';
export const AUTH_SIGNUP_COMPLETE_RESEND = 'auth-signup-complete-resend';
export const AUTH_SIGNUP_COMPLETE_CODE_FORM = 'auth-signup-complete-code-form';
export const AUTH_SIGNUP_COMPLETE_PIN_INPUT = 'auth-signup-complete-pin-input';
export const AUTH_SIGNUP_COMPLETE_SUBMIT = 'auth-signup-complete-submit';

// Signup View
export const AUTH_SIGNUP_FORM = 'auth-signup-form';
export const AUTH_SIGNUP_EMAIL = 'auth-signup-email';
export const AUTH_SIGNUP_PASSWORD = 'auth-signup-password';
export const AUTH_SIGNUP_POLICY = 'auth-signup-policy';
export const AUTH_SIGNUP_INFO = 'auth-signup-info';
export const AUTH_SIGNUP_SUBMIT = 'auth-signup-submit';

// Signin View
export const AUTH_SIGNIN_FORM = 'auth-signin-form';
export const AUTH_SIGNIN_EMAIL = 'auth-signin-email';
export const AUTH_SIGNIN_PASSWORD = 'auth-signin-password';
export const AUTH_SIGNIN_SUBMIT = 'auth-signin-submit';
export const AUTH_SIGNIN_FORGOT_PASSWORD = 'auth-signin-forgot-password';
export const AUTH_SIGNIN_LINK_MESSAGE = 'auth-signin-link-message';
export const AUTH_SIGNIN_WARNING_LINK = 'auth-signin-warning-link';
export const AUTH_SIGNIN_PIN_INPUT = 'auth-signin-pin-input';

// Signature Login View
export const AUTH_SIGNATURE_LOGIN_WRAPPER = 'auth-signature-login-wrapper';
export const AUTH_SIGNATURE_LOGIN_CONTENT = 'auth-signature-login-content';
export const AUTH_SIGNATURE_LOGIN_SPINNER = 'auth-signature-login-spinner';

// Auth Social View
export const AUTH_SOCIAL_PAGE_WRAPPER = 'auth-social-page-wrapper';
export const AUTH_SOCIAL_PAGE_CONTENT = 'auth-social-page-content';
export const AUTH_SOCIAL_PAGE_SPINNER = 'auth-social-page-spinner';

// Forgot Password View
export const AUTH_FORGOT_PASSWORD_WRAPPER = 'auth-forgot-password-wrapper';
export const AUTH_FORGOT_PASSWORD_ICON = 'auth-forgot-password-icon';
export const AUTH_FORGOT_PASSWORD_EMAIL = 'auth-forgot-password-email';
export const AUTH_FORGOT_PASSWORD_DETAILS = 'auth-forgot-password-details';
export const AUTH_FORGOT_PASSWORD_SPAM = 'auth-forgot-password-spam';
export const AUTH_FORGOT_PASSWORD_RESEND = 'auth-forgot-password-resend';

// Reset Password View
export const AUTH_RESET_PASSWORD_FORM = 'auth-reset-password-form';
export const AUTH_RESET_PASSWORD_INPUT = 'auth-reset-password-input';
export const AUTH_RESET_PASSWORD_CONFIRM = 'auth-reset-password-confirm';
export const AUTH_RESET_PASSWORD_SUBMIT = 'auth-reset-password-submit';
// Language Switcher
export const AUTH_LANGUAGE_SWITCHER_BUTTON = 'auth-language-switcher-button';
export const AUTH_LANGUAGE_SWITCHER_ICON = 'auth-language-switcher-icon';
export const AUTH_LANGUAGE_SWITCHER_TEXT = 'auth-language-switcher-text';

// Localization List Item
export const AUTH_LOCALIZATION_COUNTRY_ITEM = 'auth-localization-country-item';
export const AUTH_LOCALIZATION_COUNTRY_NAME = 'auth-localization-country-name';
export const AUTH_LOCALIZATION_FLAG = 'auth-localization-flag';
export const AUTH_LOCALIZATION_LABEL = 'auth-localization-label';
export const AUTH_LOCALIZATION_LANGUAGES = 'auth-localization-languages';
export const AUTH_LOCALIZATION_RADIO = 'auth-localization-radio';

// Verify Email Component
export const AUTH_VERIFY_EMAIL_COMPONENT = 'auth-verify-email-component';
export const AUTH_VERIFY_WARNING_ICON = 'auth-verify-warning-icon';
export const AUTH_VERIFY_WARNING_TEXT = 'auth-verify-warning-text';
export const AUTH_VERIFY_RESEND_BUTTON = 'auth-verify-resend-button';
export const AUTH_VERIFY_SENT_STATUS = 'auth-verify-sent-status';
