import { createRouter, createWebHistory } from 'vue-router';
import type { NavigationGuardNext } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { authGuard, localeGuard } from '@/router/guards';
import { authRoutes } from '@/router/routes/auth';
import { userRoutes } from '@/router/routes/user';
import { utilityRoutes } from '@/router/routes/utility';

const router = createRouter({
	history: createWebHistory(),
	routes: [...userRoutes, ...authRoutes, ...utilityRoutes],
});

router.afterEach((to) => {
	if (to.name) {
		const appStore = useAppStore();
		appStore.setCurrentScreen(to.name as string).then(() => {});
	}
});

router.beforeEach(async (to, _from, next) => {
	const localeResult = await localeGuard(to);
	if (localeResult !== true) return next(localeResult);

	const authResult = await authGuard(to);
	if (authResult !== true) return next(authResult as NavigationGuardNext);

	next();
});

export default router;
