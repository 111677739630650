<script lang="ts" setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import {
	LOCALIZATION_EMPTY_SEARCH,
	OTHER_OPTIONS,
	SAVE,
	SEARCH_COUNTRY,
	SELECT_COUNTRY_LANGUAGE_HEADER,
} from '@/locales/constants';
import { handleUserLocale } from '@/i18n';
import { useAuthStore } from '@/stores/auth';
import { getCountryName } from '@/utils/helpers';
import { supportedCountries } from '@/constants/localizations';
import LocalizationListItem from '@/views/auth/app-login/components/LocalizationListItem.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	PrimeText,
	PrimeButton,
	PrimeInput,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiSearch } from '@primeinsightsgroupllc/prime-icons';

const authStore = useAuthStore();
const { country, localization } = storeToRefs(authStore);

const selectedLocalization = ref('');
const searchQuery = ref('');

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleSelectLocalization = (localization: string) => {
	selectedLocalization.value = localization;
};

const filteredCountries = computed(() => {
	return supportedCountries.filter((country: string) =>
		getCountryName(country)
			.toLowerCase()
			.includes(searchQuery.value.toLowerCase())
	);
});

const saveSelectedLanguage = () => {
	handleUserLocale(selectedLocalization.value);
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(SELECT_COUNTRY_LANGUAGE_HEADER) }}</template>

		<template #default>
			<div class="content-wrapper">
				<LocalizationListItem
					is-selected
					:country="country"
					:selected-language="localization"
					@select-localization="handleSelectLocalization($event)"
				/>

				<PrimeInput
					v-model="searchQuery"
					:placeholder="$t(SEARCH_COUNTRY)"
					:start-icon="PiSearch"
					class="search-input-wrapper"
				/>

				<PrimeText tag="div" size="xs" weight="600" class="content-subtitle">
					{{ $t(OTHER_OPTIONS) }}
				</PrimeText>
				<div class="countries-list-container">
					<template v-if="filteredCountries.length">
						<LocalizationListItem
							v-for="item in filteredCountries"
							:key="item"
							:country="item"
							:selected-language="selectedLocalization"
							@select-localization="handleSelectLocalization($event)"
						/>
					</template>

					<template v-else>
						<div class="empty-list-fallback">
							<PrimeText class="fallback-message">
								{{ $t(LOCALIZATION_EMPTY_SEARCH) }} {{ searchQuery }}
							</PrimeText>
						</div>
					</template>
				</div>

				<div class="divider" />
			</div>
		</template>
		<template #actions>
			<PrimeButton
				full-width
				:disabled="!selectedLocalization"
				:label="$t(SAVE)"
				@click="saveSelectedLanguage"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.content-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	& :deep(.prime-input) {
		margin-bottom: 1.125rem;
	}
}
.content-subtitle {
	margin-bottom: 0.75rem;
	text-align: start;
}

.divider {
	align-self: center;
	height: 1px;
	background-color: var(--grey-100);
	margin: 0 0 0.75rem;
}

.countries-list-container {
	width: 100%;
	overflow-y: scroll;
}

:deep(.search-input-wrapper) {
	margin-bottom: 0.675rem;
}
</style>
