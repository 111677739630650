import { Echo } from '@/utils/echo';
import { ref, watch, onUnmounted } from 'vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE } from '@/locales/constants';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import type { UserBalanceUpdateInfo, FeatureFlagsData } from '@/types';
import { TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';

export const useAppPusherEventListener = (): void => {
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const { userId } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const userStore = useUserStore();
	const appStore = useAppStore();

	watch(
		() => userId.value && userAuthToken.value,
		async () => {
			if (!window.Echo && userAuthToken.value) {
				await echo.createConnection(userAuthToken.value);
			}

			if (userId.value && window.Echo) {
				channelName.value = `user-notifications-${userId.value}`;
				window.Echo.private(channelName.value)
					.listen(
						// Balance update
						'.balance-updated',
						async (event: UserBalanceUpdateInfo) => {
							await userStore.fetchUserStats();
							await userStore.fetchUserData();
							userStore.setCollectedCoins(event.new_balance);
							if (!event.is_silent) {
								notify({ body: t(UPDATED_BALANCE) });
							}
						}
					)
					.listen('.feature-flags-update', (event: FeatureFlagsData) => {
						userStore.updateFeatures(event);
					})

					.listen(
						'.gtm_track_event',
						async ({
							event_name,
							event_name_adjust,
							params,
						}: {
							event_name: TrackingEvents;
							event_name_adjust: string;
							params: Record<string, string>;
						}) => {
							appStore.addLog(
								`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
							);
							await appStore.trackEvent(event_name, params, event_name_adjust);
						}
					);
			}
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		echo.removeConnections();
	});
};
