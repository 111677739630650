<script lang="ts" setup>
import { computed, ref } from 'vue';
import { authChangePassword } from '@/api';
import type { AxiosError } from 'axios';
import type { ChangePasswordForm, ErrorResponse } from '@/types';
import useVuelidate from '@vuelidate/core';
import {
	CHANGE_PASSWORD,
	CONFIRM,
	CONFIRM_PASSWORD,
	ENTER_NEW_PASSWORD,
	OLD_PASSWORD,
} from '@/locales/constants';
import {
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import ResetPasswordButton from '@/components/common/ResetPasswordButton.vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';

const componentName = useComponentName();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const initialForm: ChangePasswordForm = {
	current_password: '',
	password: '',
	password_confirmation: '',
};

const form = ref<ChangePasswordForm>(initialForm);

const loading = ref(false);
const serverValidationResults = ref();

const { password, password_confirmation } = useValidationRules();

const passwordValue = computed(() => form.value.password);

const validation = useVuelidate(
	{
		current_password: password,
		password: password,
		password_confirmation: password_confirmation(passwordValue),
	},
	form,
	{
		$autoDirty: false,
		$externalResults: serverValidationResults,
	}
);

const {
	validate,
	getValidationFieldStatus,
	getValidationFieldText,
	isValidationError,
	clear,
} = useValidationUtils<ChangePasswordForm>(validation);

const handleChangePassword = async () => {
	clear();
	serverValidationResults.value = {};
	await validate();
	if (isValidationError.value) return;
	try {
		loading.value = true;
		const { message, status } = await authChangePassword(form.value);
		if (status === 200) {
			notify({ body: message });
			form.value = { ...initialForm };
			emit('close-modal');
		}
	} catch (error) {
		const errorData = (error as AxiosError).response?.data as ErrorResponse;
		serverValidationResults.value = errorData.errors;
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(CHANGE_PASSWORD) }}</template>
		<template #inputs>
			<PrimeInput
				v-model="form.current_password"
				type="password"
				:placeholder="$t(OLD_PASSWORD)"
				:invalid="!!getValidationFieldStatus('current_password')"
				:error-text="getValidationFieldText('current_password')?.toString()"
			/>
			<ResetPasswordButton />
			<PrimeInput
				v-model="form.password"
				type="password"
				:placeholder="$t(ENTER_NEW_PASSWORD)"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>
			<PrimeInput
				v-model="form.password_confirmation"
				type="password"
				:placeholder="$t(CONFIRM_PASSWORD)"
				:invalid="!!getValidationFieldStatus('password_confirmation')"
				:error-text="
					getValidationFieldText('password_confirmation')?.toString()
				"
			/>
		</template>
		<template #actions>
			<PrimeButton
				full-width
				:label="$t(CONFIRM)"
				@click="handleChangePassword"
			/>
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
</template>
