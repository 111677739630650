// Statistics Component
export const STATISTICS_WRAPPER = 'statistics-wrapper';
export const STATISTICS_ITEM = 'statistics-item';
export const STATISTICS_CONTENT = 'statistics-content';
export const STATISTICS_ICON = 'statistics-icon';
export const STATISTICS_INFO = 'statistics-info';
export const STATISTICS_TITLE = 'statistics-title';
export const STATISTICS_DETAILS = 'statistics-details';

// Main Sidebar
export const SIDEBAR_WRAPPER = 'sidebar-wrapper';
export const SIDEBAR_LOGO = 'sidebar-logo';
export const SIDEBAR_LOGO_LINK = 'sidebar-logo-link';
export const SIDEBAR_NAV = 'sidebar-nav';

// Right Sidebar
export const SIDEBAR_RIGHT_WRAPPER = 'sidebar-right-wrapper';
export const SIDEBAR_RIGHT_TITLE = 'sidebar-right-title';

// Balance Card
export const BALANCE_CARD_WRAPPER = 'balance-card-wrapper';
export const BALANCE_CARD_LABEL = 'balance-card-label';
export const BALANCE_CARD_AMOUNT = 'balance-card-amount';
