import { watch } from 'vue';
import { MOBILE_APP_HERE_MODAL, WELCOME_MODAL } from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels } from '@/enums';
import type { Notification } from '@/types';
import { useUserStore } from '@/stores/user';

export const useAppNotificationListener = (): void => {
	const { createNewModal } = useModalStorage();
	const messagesStore = useMessagesStore();
	const { notificationsList } = storeToRefs(messagesStore);
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { isPasswordSet } = storeToRefs(useUserStore());

	const showNotificationsModal = ({ data, id }: Notification): void => {
		const requiredOptions = {
			id,
			channel: MessageChannels.NOTIFICATION,
			shared: true,
			onClose: () => messagesStore.readNotification(id),
		};
		switch (data.event_name) {
			case 'onetime_mobile_app_is_here_001':
				createNewModal(MOBILE_APP_HERE_MODAL, {
					...requiredOptions,
				});
				break;
			case 'welcome-message':
				createNewModal(WELCOME_MODAL, {
					...requiredOptions,
				});
				break;
			default:
				break;
		}
	};

	watch(
		[userAuthToken, notificationsList, isPasswordSet],
		async (newValue) => {
			const [token, notifications, isPasswordSet] = newValue;
			if (token && notifications?.length && isPasswordSet) {
				notifications.forEach((notification) => {
					showNotificationsModal(notification);
				});
			}
		},
		{ immediate: true }
	);
};
