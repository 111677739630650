<template>
	<svg
		width="120"
		height="120"
		viewBox="0 0 120 120"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="fi_7733524" clip-path="url(#clip0_696_1425)">
			<g id="Group">
				<g id="Group_2">
					<g id="Group_3">
						<g id="Group_4">
							<path
								id="Vector"
								d="M111.17 113.805C106.457 113.805 102.546 110.079 102.36 105.328C100.501 58.0155 61.9845 19.4986 14.6725 17.6402C9.80307 17.4489 6.01083 13.3466 6.20189 8.47715C6.39318 3.60773 10.4988 -0.182856 15.365 0.00655617C42.8252 1.08514 68.6685 12.3995 88.1344 31.8651C107.6 51.331 118.914 77.1743 119.993 104.635C120.184 109.504 116.392 113.606 111.522 113.798C111.405 113.803 111.287 113.805 111.17 113.805Z"
								fill="url(#paint0_linear_696_1425)"
							/>
							<path
								id="Vector_2"
								d="M114.28 73.8109C108.956 58.1608 100.081 43.8121 88.1344 31.8655L80.2344 39.7655L114.28 73.8109Z"
								fill="url(#paint1_linear_696_1425)"
							/>
							<path
								id="Vector_3"
								d="M84.3367 112.628C80.301 112.628 76.9261 109.469 76.7073 105.391C74.905 71.7915 48.2085 45.095 14.6092 43.2927C10.3918 43.0666 7.15648 39.4642 7.38283 35.247C7.60895 31.0299 11.2137 27.7946 15.4285 28.0207C35.3899 29.0913 54.185 37.4828 68.3511 51.6492C82.5172 65.8155 90.909 84.6103 91.9795 104.572C92.2059 108.789 88.9704 112.391 84.7532 112.617C84.6137 112.625 84.4744 112.628 84.3367 112.628Z"
								fill="url(#paint2_linear_696_1425)"
							/>
							<path
								id="Vector_4"
								d="M59.8953 110.276C57.1642 110.276 54.8475 108.176 54.6243 105.407C52.8934 83.9424 36.0579 67.1068 14.5934 65.376C11.6791 65.1409 9.50708 62.5878 9.7419 59.6734C9.97696 56.7591 12.5311 54.5885 15.4445 54.8219C28.2534 55.8548 40.3275 61.4433 49.4421 70.5579C58.5567 79.6725 64.1452 91.7466 65.1781 104.556C65.4132 107.47 63.2412 110.023 60.3266 110.258C60.1819 110.27 60.0379 110.276 59.8953 110.276Z"
								fill="url(#paint3_linear_696_1425)"
							/>
							<path
								id="Vector_5"
								d="M40.2245 107.923C38.7755 107.923 37.5137 106.852 37.3137 105.376C35.7184 93.612 26.3878 84.2814 14.6235 82.6862C13.0139 82.4678 11.8859 80.9859 12.1042 79.3765C12.3226 77.7669 13.8042 76.6396 15.4139 76.8572C29.7906 78.8069 41.193 90.2094 43.1426 104.586C43.361 106.196 42.233 107.677 40.6233 107.896C40.4894 107.914 40.356 107.923 40.2245 107.923Z"
								fill="url(#paint4_linear_696_1425)"
							/>
						</g>
						<path
							id="Vector_6"
							d="M14.1176 120C21.9146 120 28.2353 113.679 28.2353 105.882C28.2353 98.0853 21.9146 91.7646 14.1176 91.7646C6.32069 91.7646 0 98.0853 0 105.882C0 113.679 6.32069 120 14.1176 120Z"
							fill="url(#paint5_linear_696_1425)"
						/>
					</g>
					<g id="Group_5">
						<path
							id="Vector_7"
							d="M96.7059 46.5882C109.571 46.5882 120 36.1591 120 23.2941C120 10.4291 109.571 0 96.7059 0C83.8409 0 73.4118 10.4291 73.4118 23.2941C73.4118 36.1591 83.8409 46.5882 96.7059 46.5882Z"
							fill="url(#paint6_linear_696_1425)"
						/>
						<path
							id="Vector_8"
							d="M80.2344 39.7657C89.3313 48.8627 104.08 48.8627 113.177 39.7657C119.235 33.7079 121.253 25.1446 119.243 17.4119H74.1689C72.1584 25.1446 74.1765 33.7076 80.2344 39.7657Z"
							fill="url(#paint7_linear_696_1425)"
						/>
						<path
							id="Vector_9"
							d="M119.864 20.8189L109.567 10.522L83.9454 36.1441L94.2572 46.4559C101.006 47.165 108.005 44.938 113.177 39.7657C118.356 34.5867 120.582 27.5761 119.864 20.8189Z"
							fill="url(#paint8_linear_696_1425)"
						/>
					</g>
				</g>
				<path
					id="Vector_10"
					d="M109.504 31.9459L100.865 23.3059L109.46 14.7116C110.605 13.5664 110.691 11.688 109.567 10.5221C108.423 9.33438 106.532 9.3212 105.368 10.4826L96.7059 19.1459L88.0431 10.4824C86.8795 9.32097 84.9889 9.33415 83.8442 10.5219C82.7205 11.688 82.8068 13.5661 83.952 14.7113L92.5464 23.3057L83.9071 31.9457C82.7584 33.0944 82.7584 34.957 83.9071 36.1057C84.4812 36.6798 85.2341 36.9669 85.9871 36.9669C86.74 36.9669 87.4906 36.6798 88.0647 36.1057L96.7059 27.4652L105.347 36.1059C105.921 36.68 106.671 36.9671 107.424 36.9671C108.177 36.9671 108.93 36.68 109.504 36.1059C110.653 34.9572 110.653 33.0946 109.504 31.9459Z"
					fill="url(#paint9_linear_696_1425)"
				/>
			</g>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_696_1425"
				x1="88.824"
				y1="31.1769"
				x2="61.2922"
				y2="58.7084"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#EAF9FA" />
				<stop offset="1" stop-color="#B3DAFE" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_696_1425"
				x1="97.2572"
				y1="59.5295"
				x2="97.2572"
				y2="33.4118"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7BACDF" stop-opacity="0" />
				<stop offset="1" stop-color="#7BACDF" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_696_1425"
				x1="69.0591"
				y1="50.9414"
				x2="45.1755"
				y2="74.8249"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#EAF9FA" />
				<stop offset="1" stop-color="#B3DAFE" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_696_1425"
				x1="49.7847"
				y1="69.6287"
				x2="34.9612"
				y2="85.1581"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#EAF9FA" />
				<stop offset="1" stop-color="#B3DAFE" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_696_1425"
				x1="36.353"
				y1="83.6471"
				x2="24.7638"
				y2="95.2363"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#EAF9FA" />
				<stop offset="1" stop-color="#B3DAFE" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_696_1425"
				x1="14.1176"
				y1="93.647"
				x2="14.1176"
				y2="119.073"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE0364" />
				<stop offset="0.2568" stop-color="#F3035F" />
				<stop offset="0.7013" stop-color="#D70252" />
				<stop offset="1" stop-color="#C00148" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_696_1425"
				x1="80.7059"
				y1="7.29412"
				x2="107.774"
				y2="34.3621"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE0364" />
				<stop offset="0.2568" stop-color="#F3035F" />
				<stop offset="0.7013" stop-color="#D70252" />
				<stop offset="1" stop-color="#C00148" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_696_1425"
				x1="96.7059"
				y1="28.0001"
				x2="96.7059"
				y2="46.1194"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#CA2E43" stop-opacity="0" />
				<stop offset="0.1896" stop-color="#BE2C40" stop-opacity="0.19" />
				<stop offset="0.7032" stop-color="#A22639" stop-opacity="0.703" />
				<stop offset="1" stop-color="#972437" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_696_1425"
				x1="110.241"
				y1="36.8177"
				x2="99.3395"
				y2="25.9161"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#CA2E43" stop-opacity="0" />
				<stop offset="0.1896" stop-color="#BE2C40" stop-opacity="0.19" />
				<stop offset="0.7032" stop-color="#A22639" stop-opacity="0.703" />
				<stop offset="1" stop-color="#972437" />
			</linearGradient>
			<linearGradient
				id="paint9_linear_696_1425"
				x1="88.712"
				y1="15.288"
				x2="101.575"
				y2="28.1513"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#EAF9FA" />
				<stop offset="1" stop-color="#B3DAFE" />
			</linearGradient>
			<clipPath id="clip0_696_1425">
				<rect width="120" height="120" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
