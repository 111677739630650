export const THEME_APP = {
	'primary-base': '#047857',
	'primary-base-03': '#E6F1EE',
	'secondary-base': '#FFB43B',
	'text-default': '#1F2937',
	'text-disabled': '#D1D5DB',
	'state-success': '#3BBA6A',
	'grey-50': '#F9FAFB',
	'grey-100': '#F3F4F6',
	'grey-200': '#E5E7EB',
	'grey-300': '#D1D5DB',
	'grey-400': '#9CA3AF',
	'grey-500': '#6B7280',
	'grey-600': '#4B5563',
	'grey-700': '#374151',
	'grey-800': '#1F2937',
	'grey-900': '#111827',
	white: '#fff',
	'radius-rounded': '0.75rem',
	'primary-font-family': '"Inter"',
	'secondary-font-family': '"Inter"',
	'shadow-base':
		'0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05)',
};

export const THEME_ICONS = {
	'base-primary': '#047857',
	'base-secondary': '#FFB43B',
	'text-default': '#1F2937',
	'text-disabled': '#D1D5DB',
};
