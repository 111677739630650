<script lang="ts" setup>
import {
	CONTINUE,
	PASSWORD,
	SET_PASSWORD,
	SET_PASSWORD_MESSAGE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useComponentName } from '@/utils/composables/useComponentName';
import { onBeforeUnmount, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useAuthStore } from '@/stores/auth';
import { sendErrorInfo } from '@/utils/errorCatching';

type SetPasswordForm = {
	password: string;
};

const componentName = useComponentName();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const form = ref<SetPasswordForm>({
	password: '',
});

const authStore = useAuthStore();
const isLoading = ref(false);

const { password } = useValidationRules();

const validation = useVuelidate({ password }, form, { $autoDirty: false });

const {
	validate,
	getValidationFieldStatus,
	getValidationFieldText,
	isValidationError,
	reset,
} = useValidationUtils<SetPasswordForm>(validation);

const handleSetPassword = async () => {
	await validate();
	if (isValidationError.value) return;

	try {
		isLoading.value = true;
		await authStore.setUserPassword(form.value.password);
	} catch (e) {
		sendErrorInfo(e, componentName);
	} finally {
		isLoading.value = true;
	}

	emit('close-modal');
};

onBeforeUnmount(() => {
	reset();
	form.value.password = '';
});
</script>

<template>
	<ModalCommonContent>
		<template #header>
			{{ $t(SET_PASSWORD) }}
		</template>
		<template #title>
			{{ $t(SET_PASSWORD_MESSAGE) }}
		</template>
		<template #inputs>
			<form class="set-user-modal-form" @submit.prevent="handleSetPassword">
				<PrimeInput
					v-model="form.password"
					autofocus
					name="password"
					type="password"
					:placeholder="$t(PASSWORD)"
					:invalid="!!getValidationFieldStatus('password')"
					:error-text="getValidationFieldText('password')?.toString()"
				/>
			</form>
		</template>
		<template #actions>
			<PrimeButton
				role="submit"
				full-width
				:label="$t(CONTINUE)"
				@click="handleSetPassword"
			/>
		</template>
	</ModalCommonContent>
	<PrimeLoader v-if="isLoading" full-page />
</template>
<style>
.set-user-modal-form {
	margin: 0.5rem 0;
}
</style>
