<script setup lang="ts">
import DesktopNav from '@/components/AppDesktopNav.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { PrimeSidebar } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import {
	SIDEBAR_WRAPPER,
	SIDEBAR_LOGO,
	SIDEBAR_LOGO_LINK,
	SIDEBAR_NAV,
} from './testAttrs';

const { isAuthorized } = storeToRefs(useAuthStore());

const { isLaptop, isTablet } = useScreenSize();
</script>

<template>
	<PrimeSidebar
		v-if="!isTablet"
		class="app-sidebar"
		:width="isLaptop ? '15rem' : '16.25rem'"
		:data-test="SIDEBAR_WRAPPER"
	>
		<div class="app-sidebar-logo" :data-test="SIDEBAR_LOGO">
			<router-link :to="{ name: 'home' }" :data-test="SIDEBAR_LOGO_LINK">
				<img src="../assets/logo-text-dark.svg" alt="Logo" />
			</router-link>
		</div>
		<div :data-test="SIDEBAR_NAV">
			<DesktopNav
				v-if="isAuthorized"
				class="app-header__col app-header__col--desktop-menu"
			/>
		</div>
	</PrimeSidebar>
</template>

<style scoped lang="scss">
.app-sidebar {
	display: flex;
	flex-direction: column;
	&-logo {
		align-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 145px;
		height: 36px;
		flex-shrink: 0;
		margin-bottom: 2.75rem;
		img {
			width: 11.25rem;
			height: 2.25rem;
		}
	}
}
</style>
