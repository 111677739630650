<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useWindowScroll } from '@vueuse/core';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { Capacitor } from '@capacitor/core';
import {
	PrimeMobileNavigationWrapper,
	PrimeNavigationMobile,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useNavigation } from '@/components/composables/useNavigation';
import { Nav, Platforms } from '@/enums';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import { MORE } from '@/locales/constants';

const { t } = useI18n();
const { y: axisY } = useWindowScroll();
const authStore = useAuthStore();
const { isMobileNavVisible } = storeToRefs(authStore);
const { isMobileApp } = useMobileApp();
const { isOpen, navigationClick, items } = useNavigation(Nav.MOBILE);

watch(
	axisY,
	(newValue, oldValue) => {
		// show mobile nav when scrolling on top direction
		if (oldValue && newValue < oldValue) {
			!isMobileNavVisible.value &&
				authStore.setMobileNavigationVisibility(true);
		} else {
			// 64 -- height of the mobile nav
			if (newValue > 64) {
				// hide mobile nav when offset from top position is more than 64px
				isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(false);
			} else {
				// show mobile nav when offset from top position is less than 64px
				!isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(true);
			}
		}
	},
	{ immediate: true }
);

const isIosPlatform = computed(
	() => isMobileApp && Capacitor.getPlatform() === 'ios'
);

const paddingBottom = computed(() =>
	isIosPlatform.value ? `calc(75px + env(safe-area-inset-bottom))` : `64px`
);

const paddingBottomHidden = computed(() =>
	isIosPlatform.value ? `75px` : `60px`
);

const bottom = computed(() =>
	isIosPlatform.value ? `calc(-65px - env(safe-area-inset-bottom))` : `-64px`
);
const height = computed(() =>
	isIosPlatform.value ? `calc(135px + env(safe-area-inset-bottom))` : `128px`
);

const platform = computed<Platforms>(
	() => Capacitor.getPlatform() as Platforms
);
</script>

<template>
	<PrimeMobileNavigationWrapper
		class="app-mobile-nav"
		:data="items"
		:platform="platform"
		:is-mobile-app="isMobileApp"
		:collapsed="!isMobileNavVisible"
		data-test-id="mobile-nav-wrapper"
	>
		<template #items="{ data }">
			<PrimeNavigationMobile
				v-for="item in data"
				:key="item.id"
				:data="{ ...item, isOpen, modalTitle: t(MORE) }"
				:data-test-id="`mobile-nav-item-${item.id}`"
				@on-modal-close="isOpen = false"
				@navigation-click="navigationClick($event as NavItem)"
			/>
		</template>
	</PrimeMobileNavigationWrapper>
</template>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;

.app-mobile-nav {
	padding-bottom: v-bind(paddingBottom);
	bottom: v-bind(bottom);
	height: v-bind(height);
	position: fixed;
	z-index: 20;
	left: 0;
	right: 0;
	contain: paint;
	background-color: var(--white);
	transition: height 0.2s ease-in-out;

	@include breakpoint(tablet) {
		display: none;
	}

	&.hidden {
		height: 0;
		transition: height 0.2s ease-in-out;
		padding-bottom: v-bind(paddingBottomHidden);
	}

	&__wrapper {
		display: grid;
		width: 100%;
		height: 100%;
	}

	&__item {
		display: flex;
		flex-direction: column;
		gap: 0.125rem;
		align-items: center;
		justify-content: center;
		padding: 0 0.625rem;
		height: 100%;
		color: var(--grey-600);

		& span {
			color: inherit !important;
		}

		&.router-link-active {
			color: var(--base-primary);
		}
	}
}
</style>
