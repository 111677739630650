import { ref, watch, type Ref, onMounted } from 'vue';
import { App as CapacitorApp } from '@capacitor/app';
import { isMobileBrowser } from '@/utils/helpers';
import { Network } from '@capacitor/network';
import { useAuthStore } from '@/stores/auth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import { initNotifications } from '@/utils/notifications';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import {
	ASK_TRACKING_PERMISSIONS_MODAL,
	UPDATE_AVAILABLE_MODAL,
} from '@/constants/modals';
import {
	maidStorage,
	platformStorage,
	showAlerts,
} from '@/utils/local-storage';
import { Capacitor } from '@capacitor/core';
import { Platforms, TrackingEvents } from '@/enums';
import { useDebounceFn } from '@vueuse/core';
import { useAppStore } from '@/stores/app';
import { useDeepLinks } from '@/utils/composables/useDeepLinks';
import { useDev } from '@/utils/composables/useDev';
import { checkVersion } from '@/api';
import { useAppRerender } from '@/utils/composables/useAppRerender';

export const useCapacitorPlugins = (
	init?: () => Promise<void>
): {
	initCapacitor: () => Promise<void>;
	deinitCapacitor: () => void;
	isNetworkConnected: Ref<boolean>;
	currentChannel: Ref<string>;
	setAdvertisingId: () => Promise<void>;
} => {
	const { listenForDeeplink } = useDeepLinks();
	const authStore = useAuthStore();
	const { userAuthToken, isAuthorized } = storeToRefs(authStore);
	const currentChannel = ref('');
	const { isMobileApp } = useMobileApp();
	const { createNewModal } = useModalStorage();
	const appStore = useAppStore();
	const { isQualificationInProgress, getAdvertisingStatus, adjustEvents } =
		storeToRefs(appStore);
	const { packageVersion } = useDev();
	const { appKey } = useAppRerender();

	const isNetworkConnected = ref(true);

	onMounted(async () => {
		const networkStatus = await Network.getStatus();
		isNetworkConnected.value = networkStatus.connected;
	});

	Network.addListener('networkStatusChange', (status) => {
		isNetworkConnected.value = status.connected;
	});

	watch(isNetworkConnected, (currentStatus, previousStatus) => {
		if (currentStatus && !previousStatus) {
			document.location.reload();
		}
	});

	const calcPlatform = () => {
		let plt = Capacitor.getPlatform() as Platforms;
		if (plt === Platforms.WEB && isMobileBrowser()) {
			plt = Platforms.WEB_MOBILE;
		}
		return plt;
	};
	const debouncedFn = useDebounceFn(() => {
		platformStorage.value = calcPlatform();
	}, 300);

	const deinitCapacitor = async () => {
		window.removeEventListener('resize', debouncedFn);
		if (isMobileApp) {
			await CapacitorApp.removeAllListeners();
			await Network.removeAllListeners();
		}
	};

	const checkForTrackingPermission = async () => {
		setTimeout(async () => {
			if (getAdvertisingStatus.value === 'Authorized') return;
			const { status } = await AdvertisingId.getAdvertisingStatus();
			if (status === 'Not Determined') {
				await createNewModal(ASK_TRACKING_PERMISSIONS_MODAL);
			}
		}, 1000);
	};

	const setAdvertisingId = async () => {
		if (isMobileApp) {
			return AdvertisingId.getAdvertisingId().then(async ({ status, id }) => {
				appStore.addLog(`MAID STATUS::: status - ${status} id - ${id}`);
				appStore.setAdvertisingStatus(status);
				if (status === 'Authorized') {
					appStore.setAdvertisingStatus(status);
					maidStorage.value = id;

					if (showAlerts.value) {
						alert(`SET TRACKING::: status - ${status} maid - ${id}`);
					}
					appStore.addLog(`SET TRACKING::: status - ${status} maid - ${id}`);
					await appStore.setTracking(id);

					// When app opened we need to wait when app-meta will be fetched
					watch(
						adjustEvents,
						async (events) => {
							if (events) {
								await appStore.trackEvent(TrackingEvents.APP_INIT);
							}
						},
						{ immediate: true }
					);
				}
			});
		}
		return Promise.resolve();
	};

	const checkAppUpdate = async () => {
		const appInfo = await CapacitorApp.getInfo();

		try {
			appStore.addLog(
				`CHECK APP VERSION::: packageVersion - ${packageVersion.value} CapacitorApp appInfo - ${appInfo.version}`
			);

			const { native_update } = await checkVersion(
				appInfo.version,
				packageVersion.value
			);

			appStore.setNativeUpdate(native_update);

			appStore.addLog(
				`CHECK APP VERSION RESPONSE::: native_update - ${native_update}`
			);

			// Native update
			if (native_update) {
				await Promise.resolve(
					createNewModal(UPDATE_AVAILABLE_MODAL, {
						useDefaultClose: false,
					})
				);
			}
		} catch (error) {
			console.log('checkVersion error', error);
		}
		await Promise.resolve();
	};

	const initCapacitor = async () => {
		platformStorage.value = calcPlatform();
		const bodyElem = document.querySelector('body');
		if (bodyElem) {
			window.addEventListener('resize', debouncedFn);
		}
		if (isMobileApp) {
			listenForDeeplink();

			CapacitorApp.addListener('backButton', () => {
				if (!isQualificationInProgress.value) {
					window.history.back();
				}
			});
			appStore.addLog('INIT CAPACITOR');
			await appStore.initAdjust();
			await Promise.allSettled([
				checkAppUpdate(),
				setAdvertisingId(),
				checkForTrackingPermission(),
			]);

			CapacitorApp.addListener('resume', async () => {
				if (!isMobileApp) return;
				if (!isAuthorized.value) return;
				await appStore.fetchAppMeta();
				if (init) {
					await init();
					appKey.value += 1;
				}
			});
		}

		watch(
			userAuthToken,
			async (token) => {
				if (token && isMobileApp) {
					initNotifications();

					if (getAdvertisingStatus.value !== 'Authorized') {
						await setAdvertisingId();
					}
				}
			},
			{ immediate: true }
		);
	};

	return {
		initCapacitor,
		deinitCapacitor,
		isNetworkConnected,
		currentChannel,
		setAdvertisingId,
	};
};
