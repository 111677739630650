<script setup lang="ts">
import { useScreenSize } from '@/utils/composables/useScreenSize';
import {
	PrimeSidebar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import BalanceCard from '@/components/common/BalanceCard.vue';
import AppStatistics from '@/components/AppStatistics.vue';
import { STATISTICS_TITLE } from '@/locales/constants';

import { SIDEBAR_RIGHT_WRAPPER, SIDEBAR_RIGHT_TITLE } from './testAttrs';

const { isLaptop, isTablet } = useScreenSize();
</script>

<template>
	<PrimeSidebar
		v-if="!isTablet"
		:width="isLaptop ? '15rem' : '21.25rem'"
		position="right"
		class="right-sidebar"
		:data-test="SIDEBAR_RIGHT_WRAPPER"
	>
		<BalanceCard />
		<PrimeText size="lg" weight="600" :data-test="SIDEBAR_RIGHT_TITLE">
			{{ $t(STATISTICS_TITLE) }}
		</PrimeText>
		<AppStatistics />
	</PrimeSidebar>
</template>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;
.right-sidebar {
	gap: 0.75rem;
	display: flex;
	flex-direction: column;
	background: var(--p-grey-100);
}
</style>
