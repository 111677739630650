<script setup lang="ts">
import { APP_REVIEW, APP_REVIEW_TEXT, OKAY } from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { InAppReview } from '@capacitor-community/in-app-review';
import review from '@/assets/icons/review-star.svg';

const emits = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleClick = () => {
	InAppReview.requestReview();
	emits('close-modal');
};
</script>

<template>
	<div id="app-review-modal">
		<ModalCommonContent>
			<template #header>
				{{ $t(APP_REVIEW) }}
			</template>
			<template #icon>
				<img :src="review" alt="congratulations" class="scale-in" />
			</template>
			<template #message>{{ $t(APP_REVIEW_TEXT) }}</template>
			<template #actions>
				<PrimeButton
					full-width
					type="gradient"
					:label="$t(OKAY)"
					@click="handleClick"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#app-review-modal {
	:deep(.modal-common-content) {
		.content-icon {
			display: flex;
			width: 100%;
			height: 2.25rem;
			margin-bottom: 0.75rem;
			justify-content: center;

			& svg {
				max-width: 2.25rem;
				max-height: 2.25rem;
			}
		}
	}
}
</style>
