<script setup lang="ts">
import {
	WELCOME_MODAL_TITLE_TEXT,
	WELCOME_MODAL_ACTION_TEXT,
	WELCOME_MODAL_SUBTITLE_TEXT,
	WELCOME_MODAL_MESSAGE_TEXT,
	WELCOME_MODAL_NOTE_TEXT,
} from '@/locales/constants';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContentV2.vue';
import heart from '@/assets/icons/heart.svg';
import {
	WELCOME_MODAL_CONTENT,
	WELCOME_MODAL_HEADER,
	WELCOME_MODAL_ICON,
	WELCOME_MODAL_TITLE,
	WELCOME_MODAL_MESSAGE,
	WELCOME_MODAL_NOTE,
	WELCOME_MODAL_BUTTON,
} from './testAttrs';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent :data-test="WELCOME_MODAL_CONTENT">
		<template #header>
			<span :data-test="WELCOME_MODAL_HEADER">{{
				$t(WELCOME_MODAL_TITLE_TEXT)
			}}</span>
		</template>

		<template #icon>
			<img
				:src="heart"
				alt="congratulations"
				class="scale-in"
				:data-test="WELCOME_MODAL_ICON"
			/>
		</template>

		<template #title>
			<span :data-test="WELCOME_MODAL_TITLE">
				{{ $t(WELCOME_MODAL_SUBTITLE_TEXT) }}
			</span>
		</template>

		<template #message>
			<span :data-test="WELCOME_MODAL_MESSAGE">
				{{ $t(WELCOME_MODAL_MESSAGE_TEXT) }}
			</span>
		</template>

		<template #custom>
			<div class="welcome-note" :data-test="WELCOME_MODAL_NOTE">
				<PrimeText size="sm" weight="400">
					{{ $t(WELCOME_MODAL_NOTE_TEXT) }}
				</PrimeText>
			</div>
		</template>

		<template #actions>
			<PrimeButton
				type="fill"
				rounded="rounded"
				full-width
				:label="$t(WELCOME_MODAL_ACTION_TEXT)"
				:data-test="WELCOME_MODAL_BUTTON"
				@click="handleCloseModal"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.welcome-note {
	border-radius: 1.125rem;
	background: #e6f1ee;
	display: flex;
	padding: 1.125rem;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
}
</style>
