import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import { NEW_PASSWORD_SENT, SET_USER_PASSWORD_MODAL } from '@/constants/modals';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useMessagesStore } from '@/stores/messages';
import { useAppStore } from '@/stores/app';

export const useAppInit = () => {
	const modalStorage = useModalStorage();
	const { activeModal } = storeToRefs(modalStorage);
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const messageStore = useMessagesStore();
	const { isPasswordSet, userData } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { authToken } = useAppCookies();
	const appStore = useAppStore();
	const { getTrackingMaid } = storeToRefs(appStore);

	// Set user token if it is in cookies
	onBeforeMount(() => {
		if (!userAuthToken.value && authToken) {
			authStore.setToken(authToken);
		}
	});

	// Close new password sent modal if it is opened
	onBeforeMount(async () => {
		if (activeModal.value.name === NEW_PASSWORD_SENT) {
			await modalStorage.shiftModal();
		}

		await appStore.fetchAppMeta();
	});

	const init = async () => {
		try {
			userStore.setInitialDataLoading(true);
			await Promise.all([
				userStore.fetchUserData(),
				userStore.fetchUserMeta(),
				userStore.fetchFeatures(),
			]);
		} finally {
			userStore.setInitialDataLoading(false);
		}

		await Promise.allSettled([
			messageStore.fetchUserNotifications(),
			messageStore.fetchUserAnnouncements(),
		]);

		if (getTrackingMaid.value) {
			await appStore.storeMaid();
		}
	};

	// Fetch user data based on token
	watch(
		userAuthToken,
		async (token) => {
			if (token) {
				await init();

				//If user does not have password - show modal to set it
				if (userData.value && !isPasswordSet.value) {
					await modalStorage.createNewModal(SET_USER_PASSWORD_MODAL, {
						useDefaultClose: false,
					});
				}
			}
		},
		{ immediate: true }
	);

	return {
		init,
	};
};
