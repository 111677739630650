import { useRoute, useRouter } from 'vue-router';
import type { RouteRecordName } from 'vue-router';
import { ref, watch, onMounted, computed } from 'vue';
import type { Ref, ComputedRef } from 'vue';
import type { NavItem } from '@/types';
import { useNavItems } from '@/utils/composables/useNavItems';
import { type Nav } from '@/enums';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { REWARDS_MODAL } from '@/constants/modals';

export const useNavigation = (
	name: Nav
): {
	navigationClick: (link: NavItem) => void;
	isOpen: Ref<boolean | string>;
	items: ComputedRef<NavItem[]>;
} => {
	const route = useRoute();
	const router = useRouter();
	const { items, desktopItems, mobileItems } = useNavItems();
	const isOpen = ref<boolean | string>(false);
	const activeItem = ref<string>('');

	const currentItems = computed(() => {
		const processItems = (items: NavItem[]): NavItem[] => {
			return items.map((item) => ({
				...item,
				active: isActive(item),
			}));
		};

		return processItems(
			name === 'desktop' ? desktopItems.value : mobileItems.value
		);
	});

	const isChildRoute = computed(() =>
		items.value
			.find((item: NavItem) => item.id === isOpen.value)
			?.children?.find(
				(subItem: NavItem) =>
					subItem.id === route.name && subItem.placed.includes('desktop')
			)
	);

	watch(
		() => route.name,
		() => {
			if (!isChildRoute.value) {
				isOpen.value = false;
			}
			activeItem.value = findActiveItem(items.value, route.name as string);
		}
	);

	watch(
		() => route.name,
		() => {
			activeItem.value = findActiveItem(items.value, route.name!);
		}
	);

	const isActive = (item: NavItem) => {
		return Boolean(
			activeItem.value === item.id ||
				item.children?.some((subItem) => subItem.id === activeItem.value)
		);
	};

	const navigationClick = (item: NavItem) => {
		if (item.handler) {
			item.handler();
			const { createNewModal } = useModalStorage();

			createNewModal(REWARDS_MODAL, {
				position: 'bottom',
			});
			return;
		}

		if (!item.children?.find((subItem) => subItem.id === activeItem.value))
			activeItem.value = item.id;

		if (item.to) {
			router.push({ name: item.to });
			return;
		}

		isOpen.value = isOpen.value === item.id ? false : item.id;
	};

	const findActiveItem = (
		items: NavItem[],
		routeName: RouteRecordName
	): string => {
		for (const item of items) {
			if (item.id === routeName) {
				return item.id;
			}
			if (item.children && item.children.length > 0) {
				const childActiveItem = findActiveItem(item.children, routeName);
				if (childActiveItem) {
					return childActiveItem;
				}
			}
		}
		return '';
	};

	onMounted(() => {
		activeItem.value = findActiveItem(items.value, route.name!);
	});

	return {
		navigationClick,
		isOpen,
		items: currentItems,
	};
};
