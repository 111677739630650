<script setup lang="ts">
import { computed, ref } from 'vue';
import { SAVE_DISCOUNT } from '@/locales/constants';
import { transformCurrency } from '@/utils/helpers';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import {
	PrimeText,
	useRippleHoverEffect,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { RewardOption } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { PiLock } from '@primeinsightsgroupllc/prime-icons';

const { accentColor = 'base-primary', option } = defineProps<{
	option: RewardOption;
	selected: boolean;
	accentColor?: 'base-primary' | 'text-default';
}>();

const emits = defineEmits<{
	(e: 'click'): void;
}>();

const { isMobile } = useScreenSize();
const { createRipple } = useRippleHoverEffect();
const { collectedCoins } = storeToRefs(useUserStore());

const buttonRef = ref<HTMLElement | null>(null);
const optionColor = computed(() => `var(--${accentColor})`);
const withoutDiscountOptionColor = computed(() =>
	accentColor === 'base-primary' ? 'var(--base-primary-500)' : 'var(--grey-500)'
);

const isOptionBlocked = computed(
	() => option.money_value > collectedCoins.value
);

const rewardOptionClick = (event: MouseEvent) => {
	emits('click');
	createRipple(buttonRef.value, event);
};
</script>

<template>
	<div :class="{ selected, blocked: isOptionBlocked }">
		<div ref="buttonRef" class="reward-option" @click="rewardOptionClick">
			<div v-if="option.discount_percent" class="discount-tag">
				<PrimeText size="xs" weight="600" color="inherit">
					{{
						$t(SAVE_DISCOUNT, {
							percentage: `${option.discount_percent}%`,
						})
					}}
				</PrimeText>
			</div>
			<PrimeText
				:size="isMobile ? 'sm' : 'lg'"
				weight="600"
				class="option-money"
			>
				{{ transformCurrency(option.money_value, option.currency) }}
			</PrimeText>

			<div v-if="isOptionBlocked" class="reward-option-lock">
				<PiLock size="1.125rem" />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.reward-option {
	overflow: hidden;
	--option-color: v-bind(optionColor);
	min-height: 3.75rem;

	padding: 1.5rem 1rem;
	background-color: var(--p-white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--p-grey-300);
	border-radius: 0.75rem;
	background: var(--White, #fff);
	box-shadow:
		0px 1px 3px 0px rgba(63, 63, 68, 0.15),
		0px 0px 0px 1px rgba(63, 63, 68, 0.05);
	cursor: pointer;
	transition: border-color 0.3s;
	position: relative;

	@include breakpoint(mobile) {
		min-height: 3.75rem;
		padding: 1.5rem;
	}

	.discount-tag {
		position: absolute;
		top: 0.25rem;
		left: 0.25rem;
		padding: 0 0.25rem;
		background-color: var(--p-base-secondary-04);
		border-radius: 3.125rem;
		color: var(--p-base-secondary);

		@include breakpoint(mobile) {
			top: 0.5rem;
			left: 0.5rem;
		}
	}

	.option-money {
		margin: 0.125rem 0;
		line-height: 1;
	}

	&:hover {
		border: 1px solid var(--p-primary-base);
	}

	.selected & {
		padding: 1.4375rem;
		color: var(--p-primary-base);
		border: 2px solid var(--p-primary-base);

		.discount-tag {
			top: 0.25rem;
			left: 0.25rem;
		}

		& .without-discount {
			color: v-bind(withoutDiscountOptionColor);
		}

		& .option-money {
			color: var(--p-primary-base);
		}
	}

	.blocked & {
		pointer-events: none;

		.option-money {
			opacity: 0.4;
		}
	}
}

.reward-option-lock {
	position: absolute;
	top: 0;
	right: 0;
	background: var(--p-grey-200);
	padding: 0.25rem 0.375rem;
	border-bottom-left-radius: 0.75rem;
}
</style>
