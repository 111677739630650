<script setup lang="ts">
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { transformCurrency } from '@/utils/helpers';
import { computed } from 'vue';
import { BALANCE } from '@/locales/constants';
import {
	BALANCE_CARD_WRAPPER,
	BALANCE_CARD_LABEL,
	BALANCE_CARD_AMOUNT,
} from '../testAttrs';

const userStore = useUserStore();

const { collectedCoins, currency } = storeToRefs(userStore);

const balanceText = computed(() => {
	return `${transformCurrency(+collectedCoins.value!, currency.value, 2)}`;
});
</script>

<template>
	<div class="balance-card" :data-test="BALANCE_CARD_WRAPPER">
		<PrimeText
			size="sm"
			weight="500"
			color="white"
			:data-test="BALANCE_CARD_LABEL"
		>
			{{ $t(BALANCE) }}:
		</PrimeText>
		<PrimeText
			size="3xl"
			weight="700"
			color="white"
			:data-test="BALANCE_CARD_AMOUNT"
		>
			{{ balanceText }}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.balance-card {
	height: 127px;
	flex-shrink: 0;
	align-self: stretch;
	border-radius: 24px;
	width: 100%;
	background: var(--p-primary-base) url('@/assets/card-gradient.png');
	margin-bottom: 0.75rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;
	background-size: cover;
}
</style>
